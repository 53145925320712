import '../pages/blank.css'
import image from './tifffavi.png'
import image1 from './tiffy2.png'
import image2 from './tiffy3.png'
function App() {

    
  
    return (
     <>
     <div className = "Apphead">
        <div className="App-headers">
            <p className='text'>Happy Birthday! 🎉🎂</p>
            <p className = "text2">
            Every year, as we celebrate your birthday, it serves as a reminder of how fortunate we are to have you in our lives. Your presence brings so much joy and positivity to those around you. Your genuine smile and caring nature light up the room and make everyone feel special.
            <p></p><p></p>
            As we gather together to celebrate this milestone, let's take a moment to reflect on the incredible journey you've had so far. You have accomplished so much and touched the lives of so many people along the way. Your determination, resilience, and unwavering spirit inspire us all.
            <p></p>
            On this special day, I want to express my heartfelt gratitude for the countless memories we've created together. From the laughter-filled moments to the deep conversations, each experience has enriched our friendship and created bonds that will last a lifetime.
            <p></p>
            <p></p>
As you blow out the candles on your birthday cake, know that you are surrounded by love and well wishes from all corners of the world. Your kindness and compassion have touched the hearts of many, and we are grateful to have you as a friend.
<p></p>
<p></p>
May the year ahead be filled with new adventures, exciting opportunities, and wonderful surprises. May you continue to grow, learn, and flourish in all aspects of your life. Remember to take time for yourself, to pursue your passions, and to nurture your dreams.
<p></p>
<p></p>
May this day mark the beginning of an extraordinary chapter in your life, filled with happiness, success, and fulfillment. Enjoy your special day, and know that you are loved and appreciated more than words can express.
<p></p>
<p></p>
Cheers to another year of beautiful moments and cherished memories! Wishing you the very best today and always.
<p></p>

            </p>
     </div>

            <div class = "center">
     <div class="grid-container">
  <div class="image-box">
  <img src={image} />
  </div>
  <div class="image-box">
  <img src={image1} />
  </div>
  <div class="image-box">
  <img src={image2} />
  </div>
</div>
</div>
     
     
     
     </div>


     </>
    );
  }
  
  export default App;
  