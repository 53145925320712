import '../pages/blank.css'
function App() {

    
  
    return (
     <>
     <div className = "Apphead">
        <div className="App-headers">
            <p className='text'>Happy Birthday! 🎉🎂</p>
            <p className = "text2">
                OK, no more jokes or chatgpt responses, thats MY bad, hopefully you don't get too mad at the images, granted THEY WERE SO HARD TO FIND. I'm not going to yap too much here (SPARE ME SOME TIME ON THE 15th!), but I hope you enjoy your 19th birthday!                                                                                                     
                <p></p>
                NO PROBLEM, by the goat,
                <p>
                </p>
                KEVIN
            </p>
            

     </div>

    
     
     
     
     </div>


     </>
    );
  }
  
  export default App;
  