import { useNavigate } from "react-router-dom";
import '../pages/blank.css'
import light from './lightbulb.jpeg'

function App() {
    
    let navigate = useNavigate(); 
    const routeChange = () =>{ 
      let path = `/bday`; 
      navigate(path);
    }
  
    return (
        
     <>
     <div className = "Apphead">
        <div className="App-headers">
        <img src={light} className="light"></img>
     <button className = "btn2" onClick = {routeChange}>turn it on</button>
     </div>
     </div>
     </>
    );
  }
  
  export default App;
  