/* eslint-disable jsx-a11y/alt-text */

import '../App.css';
import { useNavigate } from "react-router-dom";
import bday from './music.mp3'
import image from './otter_music.png'
import con from './confetti.gif'
import text from './bdaytext.png'
import gif from './cake.gif'
import balloons from './balloon.png'

function App() {
  
  let navigate = useNavigate(); 
    const routeChange = () =>{ 
      let path = `/msg`; 
      navigate(path);
    }

  let audio = new Audio(bday)

  const start = () => {
    audio.play()
    audio.loop = true
  }

  return (
    
   <>
   
   <div className = "App-head">
   <div className = "App-header">
   <img src={con} className="img1"></img>
   <img src={text}className="img3"></img>
    <img src={con}className="img2"></img>
</div>
<div className = "App-header">

<img src={gif} className="cake"/>
<img src={image} className ="image-test" onClick={start}/>
</div>
<div className="object">
<div class="move-up">
<img src = {balloons}/>
</div>
</div>
<div className="object2">
<div class="move-up">
<img src = {balloons}/>
</div>
</div>
<div className='App-header'>
<button className = "btn-primary" onClick = {routeChange}>Birthday Message!</button>
</div>
   </div>
   
   </>
  );
}
/*
<div>
<im src = {balloons}/>
</div>
*/

export default App;
