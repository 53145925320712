
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/blank";
import Bday from "./pages/bday";
import Message from "./pages/msg"
import Test from "./pages/test"

function App() {
  
  return (
   <>
   
   <BrowserRouter>
        <Routes>
          <Route className="text-3xl font-bold underline" path='/' element={<Home />} />
          <Route path='/bday' element={<Bday />} />
          <Route path='/msg' element={<Message />} />
          <Route path='/msg2' element={<Test />} />
        </Routes >
      </BrowserRouter >
   </>
  );
}

export default App;
